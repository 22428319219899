
export const getFormulaName = (indicator, formula, organization) => {
    if (!formula) return indicator?.name;
    if (formula?.name) return `${indicator?.name} | ${formula?.name}`;
    if (formula === 'sum') return `${indicator?.name} | Изменение (сумма)`
    if (formula === 'izmproc') return `${indicator?.name} | Изменение (проценты)`
    if (formula === 'proc') return `${indicator?.name} | Доля | ${organization?.label ?? 'Весь мир'}`
}

export const getUnit = (indicator, formula) => {
    if (['izmproc', 'proc'].includes(formula)) return '%';
    return formula?.unit ?? indicator?.tableUnit ?? '';
}