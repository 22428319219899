import { useEffect, useState, useCallback } from "react";

const defaultParams = {
    width: "100%",
    height: "600px",
    itemsCount: 15,
    sortDesc: false,
    sumOther: false,
    showLegend: true,
};

export const usePieGraphParams = ({ maxItemsCount }) => {

    const [params, setParams] = useState(defaultParams);

    useEffect(() => {
        maxItemsCount && setParams((prev) => ({ ...prev, itemsCount: maxItemsCount }))
    }, [maxItemsCount])

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeNumberField = (name, event) => {
        const value = Number(event.target.value);
        // if (value <= maxItemsCount)
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }))
    }

    const resetParams = useCallback(() => {
        setParams({ ...defaultParams, itemsCount: maxItemsCount ?? defaultParams.itemsCount });
    }, [maxItemsCount])

    const actions = {
        changeTextField,
        changeBoolField,
        changeNumberField,
        resetParams,
    }

    return { params, actions }
}