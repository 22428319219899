import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Header } from "../../components/header";
import { useParams } from "react-router-dom";
import { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { useGetDataByFormula, useGetDataByYear, useGetOneIndicator } from "./raceGraph.hooks";
import { useLayoutEffect, useState } from "react";
import { headerDescriptionTextSx, headerGridSx, headerTextSx } from "./raceGraph.styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ChooseYear } from "../../components/chooseYear/chooseYear";
import { ChooseRegion } from "../../components/chooseRegion/chooseRegion";
import { prepareRaceData } from "./raceGraph.utils";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const vizualizationType = 'race';
export const RaceGraph = () => {
    const { indicatorId } = useParams();
    const [selectedRegion, setSelectedRegion] = useState(null);
    const { data: indicator } = useGetOneIndicator(indicatorId);
    const { data: dataByYear, isLoading } = useGetDataByYear(indicatorId, selectedRegion);

    useLayoutEffect(() => {
        if (!dataByYear) return;
        const count = 10;
        const maxItems = 10;
        const chartData = prepareRaceData(dataByYear?.data, dataByYear?.info?.yearTo - count, count, maxItems);

        let root = am5.Root.new("chartdiv");
        root.numberFormatter.setAll({
            numberFormat: "#a",

            bigNumberPrefixes: [
                { number: 1e6, suffix: "млн." },
                { number: 1e9, suffix: "млрд." }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
        });
        var stepDuration = 2000;
        root.setThemes([am5themes_Animated.new(root)]);
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0
        }));
        chart.zoomOutButton.set("forceHidden", true);
        var yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });
        yRenderer.grid.template.set("visible", false);
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "network",
            renderer: yRenderer
        }));
        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            strictMinMax: true,
            extraMax: 0.1,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));
        xAxis.set("interpolationDuration", stepDuration / 10);
        xAxis.set("interpolationEasing", am5.ease.linear);
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "value",
            categoryYField: "network"
        }));
        series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueXWorking.formatNumber('#.# a')}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerX: am5.p100,
                    centerY: am5.p50,
                    populateText: true
                })
            });
        });
        var label = chart.plotContainer.children.push(am5.Label.new(root, {
            text: "2002",
            fontSize: "8em",
            opacity: 0.2,
            x: am5.p100,
            y: am5.p100,
            centerY: am5.p100,
            centerX: am5.p100
        }));

        function getSeriesItem(category) {
            for (var i = 0; i < series.dataItems.length; i++) {
                var dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") == category) {
                    return dataItem;
                }
            }
        }

        // Axis sorting
        function sortCategoryAxis() {
            // sort by value
            series.dataItems.sort(function (x, y) {
                return y.get("valueX") - x.get("valueX"); // descending
                //return x.get("valueX") - y.get("valueX"); // ascending
            });

            // go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                var seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    var index = series.dataItems.indexOf(seriesDataItem);
                    // calculate delta position
                    var deltaPosition =
                        (index - dataItem.get("index", 0)) / series.dataItems.length;
                    // set index to be the same as series data item index
                    if (dataItem.get("index") != index) {
                        dataItem.set("index", index);
                        // set deltaPosition instanlty
                        dataItem.set("deltaPosition", -deltaPosition);
                        // animate delta position to 0
                        dataItem.animate({
                            key: "deltaPosition",
                            to: 0,
                            duration: stepDuration / 2,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                }
            });
            // sort axis items by index.
            // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }

        var year = dataByYear?.info?.yearTo - count + 1;

        var interval = setInterval(function () {
            year++;

            if (year == dataByYear?.info?.yearTo) {
                clearInterval(interval);
                clearInterval(sortInterval);
            }

            updateData();
        }, stepDuration);

        var sortInterval = setInterval(function () {
            sortCategoryAxis();
        }, 100);

        function setInitialData() {
            var d = chartData[year];

            for (var n in d) {
                series.data.push({ network: n, value: d[n] });
                yAxis.data.push({ network: n });
            }
        }

        function updateData() {
            var itemsWithNonZero = 0;

            if (chartData[year]) {
                label.set("text", year.toString());

                am5.array.each(series.dataItems, function (dataItem) {
                    var category = dataItem.get("categoryY");
                    var value = chartData[year][category];

                    if (value > 0) {
                        itemsWithNonZero++;
                    }

                    dataItem.animate({
                        key: "valueX",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                    dataItem.animate({
                        key: "valueXWorking",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                });

                yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
            }
        }

        setInitialData();
        setTimeout(function () {
            year++;
            updateData();
        }, 50);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [dataByYear]);

    return (
        <>
            <Header />
            {isLoading && <LinearProgress />}
            <Grid container sx={headerGridSx}>
                {dataByYear && <ChooseYear yearFrom={dataByYear?.info?.yearFrom} yearTo={dataByYear?.info?.yearTo} type={vizualizationType} />}
                {!indicator ?
                    <LinearProgress /> :
                    <>
                        <Grid container justifyContent='space-between' alignItems='center' mb='10px'>
                            <Grid container sx={headerTextSx}>
                                {`${indicator?.name}`}
                                {indicator?.description &&
                                    <NoMaxWidthTooltip
                                        title={
                                            <Grid sx={{ fontSize: '14px' }}>
                                                {indicator?.description}
                                            </Grid>
                                        }
                                    >
                                        <IconButton>
                                            <InfoRoundedIcon color="info" />
                                        </IconButton>
                                    </NoMaxWidthTooltip>
                                }
                            </Grid>
                            <Grid width='auto' container gap='5px' >{indicator?.sources?.map((item) => <Chip label={item?.name} />)}</Grid>
                        </Grid>
                        {indicator?.text && <Grid container sx={headerDescriptionTextSx}>
                            {indicator?.text}
                        </Grid>}
                    </>
                }
                <ChooseRegion selectedRegion={selectedRegion} onChange={setSelectedRegion} />
                <VisualizationLinks type={vizualizationType} />
                <Grid width={'100%'} container gap={'10px'}>
                    <div id="chartdiv" style={{ width: '100%', height: '600px' }}></div>
                </Grid>
            </Grid>
        </>
    );
}

