import { Autocomplete, Grid, LinearProgress, TextField } from "@mui/material";
import { useMemo } from "react";
import { useGetOrganizations } from "./chooseRegion.hooks";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
}

const autocompleteSx = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const ChooseRegion = ({ selectedRegion, onChange }) => {
    const { data: organizations } = useGetOrganizations();


    const autocompleteHandler = (value) => {
        onChange?.(value);
    }

    const regionDictionary = useMemo(() => {
        if (!organizations) return [];
        const filtered = organizations
            ?.filter((item) => Boolean(item.agrType))
            ?.sort((a, b) => b.sort - a.sort);
        return filtered?.map((item) => ({ id: item.bitrixId, label: item.name }));
    }, [organizations]);

    return (
        <Grid container sx={gridSx}>
            {(!organizations) && <Grid width='100%'><LinearProgress /></Grid>}
            {!!organizations && <>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => autocompleteHandler(value)}
                    options={regionDictionary}
                    sx={autocompleteSx}
                    value={selectedRegion}
                    renderInput={(params) => <TextField {...params} label="Регион" />}
                />
            </>}
        </Grid>
    );
}
