import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { BaseTestChart } from './pages/baseTestChart';
import { Default } from './pages/default';
import { TreemapGraph } from './pages/treemapGraph';
import { PieGraph } from './pages/pieGraph/pieGraph';
import { RaceGraph } from './pages/raceGraph/raceGraph';


const router = createBrowserRouter([
  {
    path: "/indicator/:indicatorId/country/:countryId",
    element: <BaseTestChart />,
  },
  {
    path: "/indicator/:indicatorId/year/:year/treemap",
    element: <TreemapGraph />
  },
  {
    path: "/indicator/:indicatorId/year/:year/piechart",
    element: <PieGraph />
  },
  {
    path: "/indicator/:indicatorId/race",
    element: <RaceGraph />
  },
  {
    path: "*",
    element: <Default />,
  },
]);

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      {/* <BaseTestChart /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
