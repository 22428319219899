
const prepareOtherData = (data, params) => {
    if (!params.sumOther || !data) return [];
    const otherData = data.slice(params.itemsCount, data.length) ?? [];
    const sum = otherData.reduce((acc, item) => {
        return acc + item.value;
    }, 0);
    return [{ name: 'Другие', value: sum }];
}

export const prepareTreemapData = (data, params) => {
    if (!data) return [];
    const chartData = data
        ?.map((item) => ({ name: item.name, value: item.value }))
        ?.sort((a, b) => {
            if (params.sortDesc) return a.value - b.value
            return b.value - a.value
        })
        ?.slice(0, params.itemsCount) ?? [];
    const otherData = prepareOtherData(data, params);

    return [...chartData, ...otherData];
}