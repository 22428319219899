import { Button, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const headerSx = {
    pl: '60px',
    pt: '10px',
    pb: '10px',
    '@media (max-width: 600px)': {
        pl: '10px',
        justifyContent: 'center',
    }
}

export const Header = () => {

    return (
        <Grid container sx={headerSx}>
            <img src="https://statbase.ru/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} />
        </Grid>
    );
}
