import axios from "axios";
import { useEffect, useMemo, useState } from "react";

export const useGetAllIndicators = () => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_indicators.php`, []);

    useEffect(() => {
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    return { data, isLoading }
}
