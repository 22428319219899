import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Header } from "../../components/header";
import { useParams } from "react-router-dom";
import { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { useGetDataByFormula, useGetDataByYear, useGetOneIndicator, usePieGraphChart } from "./pieGraph.hooks";
import { useLayoutEffect, useMemo, useState } from "react";
import { headerDescriptionTextSx, headerGridSx, headerTextSx } from "./pieGraph.styles";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ChooseYear } from "../../components/chooseYear/chooseYear";
import { ChooseRegion } from "../../components/chooseRegion/chooseRegion";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";
import { usePieGraphParams } from "./pieGraphParams/pieGraphParams.hooks";
import { PieGraphParams } from "./pieGraphParams";
import { nanoid } from "nanoid";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { preparePieData } from "./pieGraph.utils";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

const vizualizationType = 'piechart';
export const PieGraph = () => {
    const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);
    const { indicatorId, year } = useParams();
    const [selectedRegion, setSelectedRegion] = useState(null);
    const { data: indicator } = useGetOneIndicator(indicatorId);
    const { data: dataByYear, isLoading } = useGetDataByYear(indicatorId, year, selectedRegion);
    const { params, actions } = usePieGraphParams({});

    const { htmlDiagram, createChart } = usePieGraphChart(chartName, dataByYear?.data, params);

    useLayoutEffect(() => {
        if (!dataByYear) return;
        const chartData = preparePieData(dataByYear?.data, params);

        let root = am5.Root.new(chartName);
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            })
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: chartName,
                categoryField: "name",
                valueField: "value",
            })
        );

        series.data.setAll(chartData);
        if (params.showLegend) {
            var legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                layout: root.horizontalLayout
            }));
            legend.data.setAll(series.dataItems);
        }

        return () => {
            root.dispose();
        };
    }, [chartName, dataByYear, params]);

    return (
        <>
            <Header />
            {isLoading && <LinearProgress />}
            <Grid container sx={headerGridSx}>
                {dataByYear && <ChooseYear yearFrom={dataByYear?.info?.yearFrom} yearTo={dataByYear?.info?.yearTo} type={vizualizationType} />}
                {!indicator ?
                    <LinearProgress /> :
                    <>
                        <Grid container justifyContent='space-between' alignItems='center' mb='10px'>
                            <Grid container sx={headerTextSx}>
                                {`${indicator?.name} | ${year}`}
                                {indicator?.description &&
                                    <NoMaxWidthTooltip
                                        title={
                                            <Grid sx={{ fontSize: '14px' }}>
                                                {indicator?.description}
                                            </Grid>
                                        }
                                    >
                                        <IconButton>
                                            <InfoRoundedIcon color="info" />
                                        </IconButton>
                                    </NoMaxWidthTooltip>
                                }
                            </Grid>
                            <Grid width='auto' container gap='5px' >{indicator?.sources?.map((item) => <Chip label={item?.name} />)}</Grid>
                        </Grid>
                        {indicator?.text && <Grid container sx={headerDescriptionTextSx}>
                            {indicator?.text}
                        </Grid>}
                    </>
                }
                <ChooseRegion selectedRegion={selectedRegion} onChange={setSelectedRegion} />
                <PieGraphParams params={params} actions={actions} />
                <VisualizationLinks type={vizualizationType} />
                <Grid width={'100%'} container gap={'10px'}>
                    <div id={chartName} style={{ width: params.width, height: params.height }}></div>
                    <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
                </Grid>
            </Grid>
        </>
    );
}

