
export const prepareRaceData = (data, yearStart, yearCount, maxItems) => {
    if (!data || !yearStart || !yearCount || !maxItems) return {};

    let yearArray = [];
    for (let i = yearStart + 1; i <= yearStart + yearCount; i++) {
        yearArray.push(i);
    }
    const resultRaceObject = yearArray.reduce((acc, currentYear) => {
        const itemsByYear = data?.filter((item) => item.year === currentYear)
            .sort((a, b) => b.value - a.value)
            .slice(0, maxItems);
        const itemsObject = itemsByYear.reduce((acc, item) => {
            return { ...acc, [item.name]: item.value }
        }, {});
        return { ...acc, [currentYear]: itemsObject }
    }, {});
    return resultRaceObject;
}
