import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useGetAllCountries, useGetAllIndicators, useGetCountriesByIndicator } from "../../pages/baseTestChart/baseTestChart.hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useGetAllCountries, useGetAllIndicators } from "../../pages/BaseTestChart/baseTestChart.hooks";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const autocompleteSx = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const ChooseIndicator = ({ beforeRedirectHandler }) => {
    const navigate = useNavigate();
    const { data: indicators } = useGetAllIndicators();
    // const { data: countries } = useGetAllCountries();
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { data: countries, isLoading: countriesIsLoading } = useGetCountriesByIndicator(selectedIndicator);

    const autocompleteHandler = (name, value) => {
        if (name === 'indicator') setSelectedIndicator(value?.id ?? null)
        if (name === 'country') setSelectedCountry(value?.id ?? null)
    }

    const indicatorDictionary = useMemo(() => {
        if (!indicators) return [];
        return indicators?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [indicators]);

    const countryDictionary = useMemo(() => {
        if (!countries) return [];
        return countries?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [countries]);


    const redirect = useCallback(() => {
        beforeRedirectHandler?.()
        navigate(`/indicator/${selectedIndicator}/country/${selectedCountry}`);
    }, [beforeRedirectHandler, selectedIndicator, selectedCountry]);

    return (
        <Grid container sx={gridSx}>
            {(!indicators || countriesIsLoading) && <Grid width='100%'><LinearProgress /></Grid>}
            {!!indicators && <>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => autocompleteHandler('indicator', value)}
                    options={indicatorDictionary}
                    sx={autocompleteSx}
                    renderInput={(params) => <TextField {...params} label="Индикатор" />}
                />
                <Autocomplete
                    size="small"
                    onChange={(_, value) => autocompleteHandler('country', value)}
                    options={countryDictionary}
                    disabled={!selectedIndicator || countriesIsLoading}
                    sx={autocompleteSx}
                    renderInput={(params) => <TextField {...params} label="Страна" />}
                />
                <Button
                    variant="outlined"
                    color="info"
                    disabled={!selectedIndicator || !selectedCountry}
                    onClick={redirect}
                    sx={buttonSx}
                >
                    Перейти
                </Button>
            </>}
        </Grid>
    );
}
