import { Chip, Grid } from "@mui/material";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";



export const VisualizationLinks = ({ type }) => {

    const { indicatorId, year, countryId } = useParams();
    const navigate = useNavigate();

    const onClick = useCallback((clickType) => {
        if (type === clickType) return;
        if (['treemap', 'piechart'].includes(clickType)) {
            navigate(`/indicator/${indicatorId}/year/${year ?? 2023}/${clickType}`);
        }
        if (clickType === 'race') {
            navigate(`/indicator/${indicatorId}/${clickType}`);
        }
        if (clickType == 'column') {
            navigate(`/indicator/${indicatorId}/country/${countryId ?? 6117}`);
        }
    }, [indicatorId, year, countryId]);


    return (
        <Grid container flexDirection='column' alignItems='flex-start' gap='5px'>
            <Grid container gap='5px'>
                <Chip
                    key={`column`}
                    color={(type === 'column') ? 'primary' : 'default'}
                    label='Columns'
                    onClick={() => onClick('column')}
                />
                <Chip
                    key={`treemap`}
                    color={(type === 'treemap') ? 'primary' : 'default'}
                    label='Treemap'
                    onClick={() => onClick('treemap')}
                />
                <Chip
                    key={`piechart`}
                    color={(type === 'piechart') ? 'primary' : 'default'}
                    label='PieChart'
                    onClick={() => onClick('piechart')}
                />
                <Chip
                    key={`race`}
                    color={(type === 'race') ? 'primary' : 'default'}
                    label='RaceChart'
                    onClick={() => onClick('race')}
                />

            </Grid>
        </Grid>
    );
}
