import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllIndicators } from "./chooseYear.hooks";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const autocompleteSx = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const ChooseYear = ({ yearFrom, yearTo, type, beforeRedirectHandler }) => {
    const navigate = useNavigate();
    const { data: indicators } = useGetAllIndicators();
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    const hasPeriod = Boolean(yearFrom) && Boolean(yearTo);

    const autocompleteHandler = (name, value) => {
        if (name === 'indicator') setSelectedIndicator(value?.id ?? null)
        if (name === 'year') setSelectedYear(value?.id ?? null)
    }

    const indicatorDictionary = useMemo(() => {
        if (!indicators) return [];
        return indicators?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [indicators]);

    const yearDictionary = useMemo(() => {
        if (!yearFrom || !yearTo) return [];
        let dictionary = [];
        for (let i = yearTo; i >= yearFrom; i--) {
            dictionary.push({ id: i, label: i });
        }
        return dictionary
    }, [yearFrom, yearTo]);


    const redirect = useCallback(() => {
        beforeRedirectHandler?.()
        if (['treemap', 'piechart'].includes(type)) {
            navigate(`/indicator/${selectedIndicator}/year/${selectedYear}/${type}`);
        }
        if (type === 'race') {
            navigate(`/indicator/${selectedIndicator}/${type}`);
        }

    }, [beforeRedirectHandler, selectedIndicator, selectedYear, type]);

    return (
        <Grid container sx={gridSx}>
            {(!indicators) && <Grid width='100%'><LinearProgress /></Grid>}
            {!!indicators && <>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => autocompleteHandler('indicator', value)}
                    options={indicatorDictionary}
                    sx={autocompleteSx}
                    renderInput={(params) => <TextField {...params} label="Индикатор" />}
                />
                {type !== 'race' &&
                    <Autocomplete
                        size="small"
                        onChange={(_, value) => autocompleteHandler('year', value)}
                        options={yearDictionary}
                        disabled={!selectedIndicator || !hasPeriod}
                        sx={autocompleteSx}
                        renderInput={(params) => <TextField {...params} label="Год" />}
                    />
                }
                <Button
                    variant="outlined"
                    color="info"
                    disabled={!selectedIndicator || (!selectedYear && type !== 'race')}
                    onClick={redirect}
                    sx={buttonSx}
                >
                    Перейти
                </Button>
            </>}
        </Grid>
    );
}
