import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { preparePieData } from "./pieGraph.utils";

export const useGetDataByFormula = (indicatorId, year, type) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&year=${year}&frmreq=0&sproc=sum&regval=all`;
    }, [indicatorId, year, type]);

    useEffect(() => {
        if (!indicatorId || !year || !type) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, year, type]);

    return { data, isLoading }
}

export const useGetDataByYear = (indicatorId, year, selectedRegion) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        if (selectedRegion) {
            const organizationId = selectedRegion.id;
            return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&year=${year}&organization=${organizationId}`;
        }
        return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&year=${year}`;
    }, [indicatorId, year, selectedRegion]);

    useEffect(() => {
        if (!indicatorId || !year) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, year, selectedRegion]);

    return { data, isLoading }
}

export const useGetOneIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const usePieGraphChart = (chartName, data, params) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const chartData = preparePieData(data, params);

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/percent.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);
        strArray.push(`\tvar chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            })
        );\r\n`);

        strArray.push(`\tvar series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: ${chartName},
                categoryField: "name",
                valueField: "value",
            })
        );\r\n`);

        strArray.push(`\tseries.data.setAll(chartData);\r\n`);
        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                layout: root.horizontalLayout
            }));\r\n`);
            strArray.push(`\tlegend.data.setAll(series.dataItems);\r\n`);
        }

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [chartName, data, params]);

    return { htmlDiagram, createChart }
}