import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export const useGetDataByFormula = (indicatorId, year, type) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&year=${year}&frmreq=0&sproc=sum&regval=all`;
    }, [indicatorId, year, type]);

    useEffect(() => {
        if (!indicatorId || !year || !type) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, year, type]);

    return { data, isLoading }
}

export const useGetDataByYear = (indicatorId, year, selectedRegion) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        if (selectedRegion) {
            const organizationId = selectedRegion.id;
            return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&year=${year}&organization=${organizationId}`;
        }
        return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&year=${year}`;
    }, [indicatorId, year, selectedRegion]);

    useEffect(() => {
        if (!indicatorId || !year) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, year, selectedRegion]);

    return { data, isLoading }
}

export const useGetOneIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}
